.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* text editor */


.container .ql-editor {
    width: 100%;
    min-height: 11in;
    padding: 1in;
    margin: 1rem;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .5);
    background-color: white;
}

.container .ql-container.ql-snow {
    border: none;
    display: flex;
    justify-content: center;
}

.container .ql-toolbar.ql-snow {
    display: flex;
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #F3F3F3;
    border: none;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .5);
}

@page {
    margin: 1in;
}

@media print {
    body {
        background: none;
    }

    .container .ql-editor {
        width: 6.5in;
        height: 9in;
        padding: 0;
        margin: 0;
        box-shadow: none;
        align-self: flex-start;
    }

    .container .ql-toolbar.ql-snow {
        display: none;
    }
}